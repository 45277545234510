export const auth = "/auth"
export const create = "/create"
export const view = "/view"
const user_management = "user-management"
const organization = "organization-management"
const customer = "customer-management"
const loan = "loan-management"
const approval_workflow = "approval-workflow"
const organization_workflow = "organization-workflow"
const faq = "faq"
const settings = "settings-configure"
const reports = "reports"
const audit_trail = "audit_trail"
const backup = "backup"
const notification = "notification"
const email_template = "email_template"
const profile = "profile"

export const PAGE_URL = {
  login: `${auth}/login`,
  resetPassword: `${auth}/reset-password`,
  forgotPassword: `${auth}/forgot-password`,
  deleteEmployee: `${auth}/delete_user`,
  redirectUser: `/redirect-user`,
  dashboard: "/dashboard",
  dashboardAnalytics: "/dashboard-analytics",
  userManagement: `/${user_management}`,
  userManagementUsers: `/${user_management}/users`,
  userManagementRoles: `/${user_management}/roles`,
  userManagementPermission: `/${user_management}/permission`,
  organization: `/${organization}`,
  organizationManagement: `/${organization}/organizations`,
  organizationManagementDetails: `/${organization}/organizations/organization-details`,
  customer: `/${customer}`,
  customerManagement: `/${customer}/customers`,
  customerManagementDetails: `/${customer}/customers/customer-details`,
  loanManagement: `/${loan}`,
  loanManagementList: `/${loan}/loans`,
  loanApprovalDetail: `/${loan}/loans/loans-detail`,
  loanManagementDetails: `/${loan}/loans-request/loan-details`,
  loanManagementRequest: `/${loan}/loans-request`,
  loanManagementTransaction: `/${loan}/loan-transactions`,
  loanManagementTransactionDetail: `/${loan}/loan-transactions/detail`,
  approvalWorkflow: `/${approval_workflow}`,
  approvalWorkflowList: `/${approval_workflow}/workflow`,
  approvalWorkflowDetails: `/${approval_workflow}/workflow/details`,
  createApprovalWorkflow: `/${approval_workflow}/workflow/createApprovalWorkflow`,
  organizationWorkflow: `/${organization_workflow}/`,
  organizationWorkflowList: `/${organization_workflow}/workflow`,
  organizationWorkflowDetails: `/${organization_workflow}/workflow/organization-workflow`,
  faqManagement: `/${faq}`,
  faqManagementList: `/${faq}/faq-management`,
  settings: `/${settings}`,
  settingsList: `/${settings}/settings`,
  settingConfigure: `/${settings}/settings/settingConfigure`,
  updateSettingConfigure: `/${settings}/settings/createSetting`,
  reports: `/${reports}`,
  reportList: `/${reports}/list`,
  auditTrail: `/${audit_trail}`,
  auditTrailList: `/${audit_trail}/list`,
  backup: `/${backup}`,
  backupList: `/${backup}/list`,
  notification: `/${notification}`,
  notificationManage: `/${notification}/notification_manage`,
  email_template: `/${email_template}`,
  email_templateList: `/${email_template}/template`,
  email_template_details: `/${email_template}/template/template-details`,
  profile: `/${profile}`
}

import { lazy } from "react"
import Loadable from "../layouts/shared/Loadable"
import { PAGE_URL, auth } from "./path"
import { PERMISSION_MODULE } from "src/interface"
import ModuleProvider from "src/components/HOC/ModuleProvider"

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import("../layouts/FullLayout")))
const BlankLayout = Loadable(lazy(() => import("../layouts/BlankLayout")))

/* ****Pages***** */
const Login = Loadable(lazy(() => import("../views/pages/authentication/pages/Login")))
const ResetPassword = Loadable(lazy(() => import("../views/pages/authentication/pages/ResetPassword")))
const ForgetPassword = Loadable(lazy(() => import("../views/pages/authentication/pages/ForgetPassword")))
const DeleteEmployeeForm = Loadable(lazy(() => import("../views/pages/authentication/pages/DeleteEmployeeForm")))
const DefaultDashboard = Loadable(lazy(() => import("../views/pages/dashboard/default/index")))
const AnalyticsDashboard = Loadable(lazy(() => import("../views/pages/dashboard/analytics/index")))
const UsersComponent = Loadable(lazy(() => import("../views/pages/user-management/users/index")))
const RolesComponent = Loadable(lazy(() => import("../views/pages/user-management/roles/index")))
const PermissionComponent = Loadable(lazy(() => import("../views/pages/user-management/permission/index")))
const Organization = Loadable(lazy(() => import("../views/pages/organization-management/index")))
const ViewOrganization = Loadable(lazy(() => import("../views/pages/organization-management/view/index")))
const CustomerManagement = Loadable(lazy(() => import("../views/pages/customer-management/index")))
const ViewCustomerManagement = Loadable(lazy(() => import("../views/pages/customer-management/view/index")))
const LoanComponent = Loadable(lazy(() => import("../views/pages/loan-management/index")))
const ViewApprovalLoanDetails = Loadable(lazy(() => import("../views/pages/loan-management/approve-loan/index")))

const ViewLoanDetails = Loadable(lazy(() => import("../views/pages/loan-management/loan-request/view/index")))
const LoanTransaction = Loadable(lazy(() => import("../views/pages/loan-management/transaction/index")))
const PaymentDetail = Loadable(lazy(() => import("../views/pages/loan-management/transaction/paymentDetail")))

const LoanRequest = Loadable(lazy(() => import("../views/pages/loan-management/loan-request/index")))
const ApprovalWorkFlow = Loadable(lazy(() => import("../views/pages/loan-management/approval-workflow/index")))
const CreateWorkFlow = Loadable(
  lazy(() => import("../views/pages/loan-management/approval-workflow/CreateWorkFlow"))
)
const ViewWorkFlow = Loadable(lazy(() => import("../views/pages/loan-management/approval-workflow/viewWorkflow")))
const FaqManagement = Loadable(lazy(() => import("../views/pages/faq-management")))

const SettingList = Loadable(lazy(() => import("../views/pages/settings_configuration/index")))
const SettingConfigure = Loadable(lazy(() => import("../views/pages/settings_configuration/view/ViewSettingList")))
const UpdateSettingConfigure = Loadable(lazy(() => import("../views/pages/settings_configuration/view/form/index")))

const OrganizationWorkflow = Loadable(lazy(() => import("../views/pages/loan-management/organization-workflow/index")))
const OrganizationWorkflowDetail = Loadable(lazy(() => import("../views/pages/loan-management/organization-workflow/view/index")))

const AuditTrails = Loadable(lazy(() => import("../views/pages/audit-trail/index")))
const Backup = Loadable(lazy(() => import("../views/pages/backups/index")))
const ReportAnalytics = Loadable(lazy(() => import("../views/pages/reports/index")))
const Notification = Loadable(lazy(() => import("../views/pages/notification/index")))
const EmailTemplate = Loadable(lazy(() => import("../views/pages/email-template/index")))
const ViewEmailTemplate = Loadable(lazy(() => import("../views/pages/email-template/view/index")))

const Profile = Loadable(lazy(() => import("../views/pages/profile/index")))

const Router = [
  {
    path: auth,
    element: <BlankLayout />,
    isProtected: false,
    children: [
      {
        path: `${PAGE_URL.login}`,
        element: <Login />,
        isProtected: false,
        children: [],
      },
      {
        path: `${PAGE_URL.login}/:parent`,
        element: <Login />,
        isProtected: false,
        children: [],
      },
      {
        path: `${PAGE_URL.login}/:parent/:child`,
        element: <Login />,
        isProtected: false,
        children: [],
      },
      {
        path: PAGE_URL.resetPassword,
        element: <ResetPassword />,
        isProtected: false,
        children: [],
      },
      {
        path: PAGE_URL.forgotPassword,
        element: <ForgetPassword />,
        isProtected: false,
        children: [],
      },
      {
        path: PAGE_URL.deleteEmployee,
        element: <DeleteEmployeeForm />,
        isProtected: false,
        children: [],
      },
    ],
  },
  {
    path: "/",
    element: <FullLayout />,
    isProtected: true,
    children: [
      {
        path: PAGE_URL.dashboard,
        element: <DefaultDashboard />,
        isProtected: true,
        children: [],
      },
      {
        path: PAGE_URL.dashboardAnalytics,
        element: <AnalyticsDashboard />,
        isProtected: true,
        children: [],
      },
    ],
  },
  {
    path: PAGE_URL.userManagement,
    element: <FullLayout />,
    isProtected: true,
    children: [
      {
        path: PAGE_URL.userManagementUsers,
        element: <UsersComponent />,
        module: PERMISSION_MODULE.User_Management,
        isProtected: true,
        children: [],
      },
      {
        path: PAGE_URL.userManagementRoles,
        element: <RolesComponent />,
        module: PERMISSION_MODULE.Role_Management,
        isProtected: true,
        children: [],
      },
      {
        path: PAGE_URL.userManagementPermission,
        element: <PermissionComponent />,
        isProtected: true,
        children: [],
      },
    ],
  },
  {
    path: PAGE_URL.settings,
    element: <FullLayout />,
    isProtected: true,
    children: [
      {
        path: PAGE_URL.settingsList,
        element: <SettingList />,
        module: PERMISSION_MODULE.Settings_Configuration_Management,
        isProtected: true,
        children: [],
      },
      {
        path: PAGE_URL.settingConfigure,
        element: <SettingConfigure />,
        module: PERMISSION_MODULE.Settings_Configuration_Management,
        isProtected: true,
        children: [],
      },
      {
        path: PAGE_URL.updateSettingConfigure,
        element: <UpdateSettingConfigure />,
        module: PERMISSION_MODULE.Settings_Configuration_Management,
        isProtected: true,
        children: [],
      },
    ],
  },
  {
    path: PAGE_URL.organization,
    element: <FullLayout />,
    isProtected: true,
    children: [
      {
        path: PAGE_URL.organizationManagement,
        element: <Organization />,
        module: PERMISSION_MODULE.Organization_Management,
        isProtected: true,
        children: [],
      },
      {
        path: PAGE_URL.organizationManagementDetails,
        element: (
          <ModuleProvider module={PERMISSION_MODULE.Organization_Management}>
            <ViewOrganization />
          </ModuleProvider>
        ),
        module: PERMISSION_MODULE.Organization_Management,
        isProtected: true,
        children: [],
      },
    ],
  },
  {
    path: PAGE_URL.customer,
    element: <FullLayout />,
    isProtected: true,
    children: [
      {
        path: PAGE_URL.customerManagement,
        element: <CustomerManagement />,
        isProtected: true,
        children: [],
      },
      {
        path: PAGE_URL.customerManagementDetails,
        element: <ViewCustomerManagement />,
        isProtected: true,
        children: [],
      },
    ],
  },
  {
    path: PAGE_URL.loanManagement,
    element: <FullLayout />,
    isProtected: true,
    children: [
      {
        path: PAGE_URL.loanManagementList,
        element: (
          <ModuleProvider module={PERMISSION_MODULE.Loans_Management}>
            <LoanComponent />
          </ModuleProvider>
        ),
        module: PERMISSION_MODULE.Loans_Management,
        isProtected: true,
        children: [],
      },
      {
        path: PAGE_URL.loanApprovalDetail,
        element: <ViewApprovalLoanDetails />,
        module: PERMISSION_MODULE.Loans_Management,
        isProtected: true,
        children: [],
      },
      {
        path: PAGE_URL.loanManagementDetails,
        element: <ViewLoanDetails />,
        module: PERMISSION_MODULE.Loans_Request_Management,
        isProtected: true,
        children: [],
      },
      {
        path: PAGE_URL.loanManagementRequest,
        element: <LoanRequest />,
        module: PERMISSION_MODULE.Loans_Request_Management,
        isProtected: true,
        children: [],
      },
      {
        path: PAGE_URL.loanManagementTransaction,
        element: <LoanTransaction />,
        module: PERMISSION_MODULE.Loans_Transaction_Management,
        isProtected: true,
        children: [],
      },
      {
        path: PAGE_URL.loanManagementTransactionDetail,
        element: <PaymentDetail />,
        module: PERMISSION_MODULE.Loans_Transaction_Management,
        isProtected: true,
        children: [],
      },
    ],
  },
  {
    path: PAGE_URL.approvalWorkflow,
    element: <FullLayout />,
    isProtected: true,
    children: [
      {
        path: PAGE_URL.approvalWorkflowList,
        element: <ApprovalWorkFlow />,
        module: PERMISSION_MODULE.Workflow_Management,
        isProtected: true,
        children: [],
      },
      {
        path: PAGE_URL.createApprovalWorkflow,
        element: <CreateWorkFlow />,
        module: PERMISSION_MODULE.Workflow_Management,
        isProtected: true,
        children: [],
      },
      {
        path: PAGE_URL.approvalWorkflowDetails,
        element: <ViewWorkFlow />,
        module: PERMISSION_MODULE.Workflow_Management,
        isProtected: true,
        children: [],
      },
    ],
  },
  {
    path: PAGE_URL.organizationWorkflow,
    element: <FullLayout />,
    isProtected: true,
    children: [
      {
        path: PAGE_URL.organizationWorkflowList,
        element: <OrganizationWorkflow />,
        module: PERMISSION_MODULE.Organization_Workflow_Management,
        isProtected: true,
        children: [],
      },
      {
        path: PAGE_URL.organizationWorkflowDetails,
        element: <OrganizationWorkflowDetail />,
        module: PERMISSION_MODULE.Organization_Workflow_Management,
        isProtected: true,
        children: [],
      },
    ],
  },

  {
    path: PAGE_URL.faqManagement,
    element: <FullLayout />,
    isProtected: true,
    children: [
      {
        path: PAGE_URL.faqManagementList,
        element: <FaqManagement />,
        module: PERMISSION_MODULE.FAQ,
        isProtected: true,
        children: [],
      },
    ],
  },

  {
    path: PAGE_URL.auditTrail,
    element: <FullLayout />,
    isProtected: true,
    children: [
      {
        path: PAGE_URL.auditTrailList,
        element: <AuditTrails />,
        module: PERMISSION_MODULE.Audit_Trails,
        isProtected: true,
        children: [],
      },
    ],
  },
  {
    path: PAGE_URL.backup,
    element: <FullLayout />,
    isProtected: true,
    children: [
      {
        path: PAGE_URL.backupList,
        element: <Backup />,
        module: PERMISSION_MODULE.Backups,
        isProtected: true,
        children: [],
      },
    ],
  },
  {
    path: PAGE_URL.reports,
    element: <FullLayout />,
    isProtected: true,
    children: [
      {
        path: PAGE_URL.reportList,
        element: <ReportAnalytics />,
        module: PERMISSION_MODULE.Reports_Analytics,
        isProtected: true,
        children: [],
      },
    ],
  },
  {
    path: PAGE_URL.notification,
    element: <FullLayout />,
    isProtected: true,
    children: [
      {
        path: PAGE_URL.notificationManage,
        element: <Notification />,
        isProtected: true,
        children: [],
      },
    ],
  },
  {
    path: PAGE_URL.email_template,
    element: <FullLayout />,
    isProtected: true,
    children: [
      {
        path: PAGE_URL.email_templateList,
        element: <EmailTemplate />,
        module: PERMISSION_MODULE.Email_Template,
        isProtected: true,
        children: [],
      },
      {
        path: PAGE_URL.email_template_details,
        element: <ViewEmailTemplate />,
        module: PERMISSION_MODULE.Email_Template,
        isProtected: true,
        children: [],
      },
    ],
  },

  {
    path: PAGE_URL.profile,
    element: <FullLayout />,
    isProtected: true,
    children: [
      {
        path: PAGE_URL.profile,
        element: <Profile />,
        isProtected: true,
        children: [],
      },
    ],
  },

]

export default Router
